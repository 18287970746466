<template>
  <div class="form-control no-border">
    <a-row>
      <a-col :span="col">
        <div class="label">{{ title }}</div>
      </a-col>
      <a-col :span="valueCol === 0 ? 24 - col : valueCol">
        <div class="input">
          {{ inputValue }}
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    col: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 0
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)
    const isInput = ref(false)
    const isFocus = ref(false)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      isInput.value = !!e.target.value
      emit('ok', e.target.value)
      emit('update:value', e.target.value)
    }

    const onFocus = () => {
      isFocus.value = true
    }

    const onBlur = () => {
      isFocus.value = false
    }

    return {
      inputValue,
      isInput,
      isFocus,
      onChange,
      onFocus,
      onBlur
    }
  },

  computed: {
    isMust () {
      return this.validate !== null
    },

    hasError () {
      return this.validate && this.validate.error && !this.isInput
    }
  }
})
</script>

<style lang="less" scoped>
@textHeight: 0.85rem;
@validateHeight: 0.4rem;
@errorColor: #f50;
@blurColor: rgb(0, 110, 255);
.form-control {
  position: relative;
  .label {
    line-height: @textHeight;
    &:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
  .input {
    position: relative;
    padding-right: 10px;
    height: @textHeight;
    display: flex;
    line-height: 0.4rem;
    align-items: center;
  }
}
</style>
